<template>
  <article class="article article--bg-corporate-medium" v-lazy-container="{ selector: 'img' }">
    <section class="article__mood">
      <img :data-src="mood" class="article__mood-image">
      <div data-aos="fade-in" class="article__mood-text">
        <h1 class="article__mood-title">{{ $t('gerlinggarden.title') }}</h1>
        <a :href="`mailto:${mail}`" class="contact__mail">{{ $t('contact.mail') }}</a>
      </div>
    </section>
    <section class="article__text" data-aos="fade-in" data-aos-delay="500">
      <h4 class="contact__headline">{{ $t('contact.headline') }}</h4>
      <div class="contact__addresses">
        <p>
          <strong>{{ address1.contact }}</strong><br>
          {{ address1.address }}<br>
          <a :href="`tel:` + address1.phone | trimNumber">{{ address1.phone }}</a>
        </p>
        <p>
          <strong>{{ address2.contact }}</strong><br>
          {{ address2.address }}<br>
          <a :href="`tel:` + address2.phone | trimNumber">{{ address2.phone }}</a>
        </p>
      </div>
      <p><strong class="contact__meta">Joint Venture by</strong></p>
      <img :data-src="logoSVG" class="joint-venture">
    </section>
  </article>
</template>
<script>
import json from '@/locales/de.json'

export default {
  name: 'Contact',
  components: {
  },
  metaInfo() {
    return {
      title: this.$t('contact.meta_title'),
      meta: [
        { name: 'description', content:  this.$t('global.meta_description') },
        { name: 'keywords', content:  this.$t('global.meta_keywords') }
      ]
    }
  },
  data () {
    return {
      mail: json.contact.mail,
      address1: json.contact.address1,
      address2: json.contact.address2,
      mood: require('../assets/images/contact/mood.jpg'),
      logoSVG: require('../assets/images/logo_pq.svg'),
    }
  },
  filters: {
    trimNumber: function (value) {
      if (!value) return ''
      value = value.toString()
      return value.replace(/\s+/g, '').replace(/–/g, '')
    }
  }
}
</script>
<style lang="scss" scoped>
$mood-height: 312px;
$mood-height-mobile: 365px;

h3 {
  @include font-size(18);
}

.article--bg-corporate-medium {
  flex-grow: 1;
  width: 100%;

  p,
  strong,
  [href^=tel] {
    font-family: $body-font-family;
  }

  p {
    white-space: pre;
  }
}

.article__mood {
  @include tablet() {
    flex-grow: 0;
    flex-direction: row;
    width: calc(100% - 48px);
    align-items: center;
    min-height: $mood-height;
    justify-content: flex-start;
  }

  flex-direction: column;
  min-height: $mood-height-mobile;
  align-items: flex-start;
  justify-content: center;
}

.article__text {
  @include grid-spacing('desktop-xl', 2, 0);

  padding-top: $container-padding * 2;
  padding-left: 0;
}

.article__mood-text {
  @include desktop {
    flex: 0 0 60%;
  }

  flex: 0 1 50%;
  display: flex;
  flex-direction: column;
}

.article__mood-title {
  @include grid-spacing('desktop-xl', 2, 0);

  @include font-size(60);
}

.article__mood-image {
  @include tablet {
    height: $mood-height;
    max-width: 100%;
  }

  @include desktop-xl {
    max-width: $desktop-xl;
  }

  height: $mood-height-mobile;
  width: calc(100vw + #{$container-padding * 2});
}

.contact {
  &__headline {
    @include font-size(18);
  }

  &__meta {
    @include font-size(16);
  }

  &__mail {
    @include desktop {
      @include font-size(24);
    }

    @include grid-spacing('desktop-xl', 2, 0);

    @include font-size(16);

    text-decoration: underline;
    text-underline-offset: 6px;
    transition: all 0.3s 0s ease-in-out;
    display: inline-block;
    margin: $container-padding 0 0;
    word-wrap: break-word;

    &:hover {
      text-decoration-color: $color-corporate-medium;
      color: $color-shade-lightest;
    }
  }

  &__addresses {
    @include tablet() {
      display: flex;
    }

    margin-bottom: 60px;

    p:last-child {
      @include tablet() {
        padding-left: $container-padding * 3;
      }
    }
  }
}
</style>
